import './css/app.css';

const subscriptions = {
    "Lender": [
        "Few Clicks to create a promissory note",
        "Easy Notifications",
    ],
    "Borrower": [
        "Few Clicks to create a promissory note",
        "Easy Notifications",
        "Option to Insure the borrower",
        "Re-create the promissory note before expiry",
        "Legal Advisory"
    ],
    "Third Party Agreement": [
        "Few Clicks to create a agreement",
        "Easy to Track the details",
        "Legal Advisory"

    ]

}

export const App = () => {
    return (
        <div className='container'>
            <div className='d-flex justify-content-around gap-3 p-3'>
                {
                    Object.keys(subscriptions).map((key) => {
                        return (
                            <>
                                <div className='col-3 bg-theme border-5'>
                                    <h5 className='p-1'>{key}</h5>
                                    <ul>
                                        {
                                            subscriptions[key].map((item) => {
                                                return <li>{item}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </>
                        );
                    })
                }
            </div>
        </div>
    )
}