import { useState } from 'react';
import { Dialog } from 'primereact/dialog';

import './css/header.css';
export default function AppHeader() {
    const [isUserLoggedIn,setLogin] = useState(false);
    const [visible,setVisible] = useState(false);
    return (
        <>
            <div className='row header align-items-center'>
                <div className='col-1'>
                    <div className='w-10' >
                        <img src="./promise.svg"/>
                    </div>
                </div>
                <div className="col-8 d-flex gap-4">
                    <span>For lender</span>
                    <span>For Borrower</span>
                    <span>Third party Agreement</span>
                </div>
                {
                    isUserLoggedIn ? 
                    <div className="col-3 color d-flex justify-content-end">
                        <div className='radius-50 img'>
                        <span>A</span>
                        </div>
                        <div></div>
                    </div>:
                    <div className='col-3 color d-flex justify-content-end gap-1'>
                        <button onClick={() => setVisible(true)}>Sign Up</button>
                        <button>Login</button>
                    </div>
                }
            </div>
            <Dialog header="Header" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
    <p className="m-0">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
</Dialog>
        </>
    );
}