import { useState,useEffect } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function ReactPdfViewerApp() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);


  function onDocumentLoadSuccess(data) {
    console.log(data);
    setNumPages(data.numPages);
  }
  

  return (
    <div>
      <div className=''>
      <Document file="./sample.pdf" onLoadSuccess={onDocumentLoadSuccess} width="100%" height="100%">
        <Page pageNumber={1} />
      </Document>
      </div>
      
      <p className='bg-blue-500 text-white p-4 text-2xl'>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}

export default ReactPdfViewerApp;
