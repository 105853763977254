import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import './login.css';
export function Login() {
    const onSuccess = (credentialResponse) => {
        console.log(credentialResponse);
        const token = credentialResponse.credential;
    }
    
    return (
        <>
            <div className="d-flex justify-content-center gap-4 p-2">
                <div className='d-flex flex-column'>
                    <h1>Login </h1>
                    <div className='p-1 flex flex-row gap-1'>
                        <label className='align-center'> User name: </label>
                        <input type='text' placeholder='Enter user '
                            className='text-lg p-1' />
                    </div>
                    <div className='p-1 flex flex-row gap-1'>
                        <label className='align-center'> &nbsp;Password:</label>
                        <input type='password' placeholder='Password ' className='text-lg p-1' />
                    </div>
                    <div className='d-flex'>
                        <button>Login</button>
                        <GoogleOAuthProvider clientId="252011822570-07ber95chddcjpl0e85v8lm5qr4f0hj8.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={onSuccess}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            useOneTap
                        />;
                        </GoogleOAuthProvider>
                    </div>
                </div>
            </div>
        </>
    );
}